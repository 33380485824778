import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import form from './reducers/form';
import error from './reducers/error';
import auth from './reducers/auth';
import posts from './reducers/posts';
import theme from './reducers/theme';
import word from './reducers/word';
import modeSelect from './reducers/modeSelect';
import administration from './reducers/administration';
import interaction from './reducers/interaction';
import formData from './reducers/formData';
import exerciseHistory from './reducers/exerciseHistory';
import routineBuilder from './reducers/routineBuilder';
import routineSelect from './reducers/routineSelect';
import viewHistory from './reducers/viewHistory';
import authMiddleware from './middleware/auth';
import errorMiddleware from './middleware/error';
import themeMiddleware from './middleware/theme';
import modeMiddleware from './middleware/modeSelect';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  combineReducers({ form, error, auth, posts, theme, word, modeSelect, interaction, exerciseHistory, routineBuilder, routineSelect, administration, viewHistory, formData}),
  composeEnhancers(
    applyMiddleware(thunk, authMiddleware, errorMiddleware, themeMiddleware, modeMiddleware)
  )
);

